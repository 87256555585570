import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Button, Container, Form, FormGroup, Row, Col, Tab, Nav} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {getJWTHeaders} from "../../common/server";



class Addfilm extends Component {


  emptyFilm = {
    
    idfilm: this.props.match.params.idfilm,
    originaltitle:'',
    internationaltitle: '' ,
    genres:{
      idgenres: 0
    }
    
  };

  constructor(props) {
    super(props);
    this.state = {
      item: this.emptyFilm ,
      idfilm:this.props.match.params.idfilm,
      operation:"save",
      genres:[],
      filmtypes:[],
      covers:[],
      kalturafilm:[],
      activeTab: '1'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
   
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }


  async componentDidMount() {
    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

    if (this.props.match.params.idfilm !== 'new') {

        

      const currentfilm = await (await fetch(process.env.REACT_APP_SERVER_URL+`/film/${this.props.match.params.idfilm}`,options)).json();
      this.setState({item: currentfilm,
                    operation:"update" });
    }else{
      let item = {...this.state.item};
    item["idfilm"] = null;     
      this.setState({item,operation:"save" });
    }


    const genres = await (await fetch(process.env.REACT_APP_SERVER_URL+`/genres`,options)).json();
    this.setState({genres:genres });

    const filmtypes = await (await fetch(process.env.REACT_APP_SERVER_URL+`/film/filmtypes`,options)).json();
    this.setState({filmtypes:filmtypes });

    const kalturafilms = await (await fetch(process.env.REACT_APP_SERVER_URL+`/kalturafilm`,options)).json();
    this.setState({kalturafilm:kalturafilms });

    const filmcovers = await (await fetch(process.env.REACT_APP_SERVER_URL+`/film/cover`,options)).json();
    this.setState({covers:filmcovers });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = {...this.state.item};
    if(target.name==='idgenres'){
      if(item.genres!=null)
      item.genres.idgenres=value;
      else{
        let genres = {
          idgenres: value
        }
        item.genres= genres
      }

    }else{
    item[name] = value;
    } 
    this.setState({item});   
  }

  handleChangeEditor(html,currentId) {
    console.log(html);
    console.log(currentId);
  
    const value = html;
    const name =  currentId;
    let item = {...this.state.item};
    
    item[name] = value;
    
    this.setState({item});    
  }

  async handleSubmit(event) {
    
    event.preventDefault();
    const item = this.state.item;
    const operation = this.state.operation;
  
    

    await fetch(process.env.REACT_APP_SERVER_URL+'/film/'+operation, {
      method: 'POST',
      headers: getJWTHeaders(),
      body: JSON.stringify(item),
    })

    if(item.idfilm)
    this.props.history.push('/filmdetail/'+item.idfilm)
    else
    this.props.history.push('/filmtable')
    
  }



  render() {
    const {item} = this.state;
    const title = <h2>{item.idfilm ? 'Edit Film' : 'Add Film'} </h2>;

    return <div>
     
      <Container fluid="xl">
        {title}

      <Form onSubmit={this.handleSubmit}>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <Form.Label for="internationaltitle">International title</Form.Label>
              <Form.Control  type="text" name="internationaltitle" id="internationaltitle" value={item.internationaltitle || ''}
                     onChange={this.handleChange} />
            </FormGroup>
          </Col>
          <Col md={6}>
             <FormGroup>
              <Form.Label for="originaltitle">Original title</Form.Label>
              <Form.Control  type="text" name="originaltitle" id="originaltitle" value={item.originaltitle || ''}
                     onChange={this.handleChange} />
            </FormGroup>
          </Col>
        </Row>

      <Row form>
        <Col md={6}>

          <FormGroup>
            <Form.Label for="email">Email</Form.Label>
            <Form.Control  type="text" name="mail" id="mail" value={item.mail || ''}
                   onChange={this.handleChange} />
          </FormGroup>
          </Col>
        <Col md={6}>
          <FormGroup>
            <Form.Label for="year">Year of production</Form.Label>
            <Form.Control  type="number" name="year" id="year" value={item.year || new Date().getFullYear()}
                   onChange={this.handleChange} />
          </FormGroup>  
          </Col>
      </Row>

      <Row form>
        <Col md={6}>
          <FormGroup>
        <Form.Label for="idgenres">Genre</Form.Label>
        <Form.Select type="select" name="idgenres" id="idgenres" value={item.genres!=null && item.genres.idgenres || ''} onChange={this.handleChange} required>
        <option value="" disabled> -- Select a genre -- </option>            
      {this.state.genres.map((genre) => <option value={genre.idgenres}>{genre.genre}</option>)}
        </Form.Select>
      </FormGroup>
      </Col>
        <Col md={6}>
      <FormGroup>
            <Form.Label for="playbackresolution">Playback video resolutions: </Form.Label>
            <Form.Control  type="text" name="playbackresolution" id="playbackresolution" value={item.playbackresolution || ''}
                   onChange={this.handleChange} />
          </FormGroup>  
          </Col>
      </Row>

      <Row form>
        <Col md={6}>
          <FormGroup>
        <Form.Label for="kalturaid">Kaltura</Form.Label>
        <Form.Select type="select" name="kalturaid" id="kalturaid" value={item.kalturaid} onChange={this.handleChange} >
        <option value="0"> -- Select a kaltura film -- </option>
            
      {this.state.kalturafilm.map((ckalturafilm) => <option value={ckalturafilm.id}>{ckalturafilm.name}</option>)}
        </Form.Select>
      </FormGroup>
      </Col>
        <Col md={6}>
        <FormGroup>
            <Form.Label for="runtime">Runtime: </Form.Label>
            <Form.Control  type="text" name="runtime" id="runtime" value={item.runtime || ''}
                   onChange={this.handleChange} />
          </FormGroup>  
          </Col>
      </Row>

      <Row form>
       
          <Col md={6}>
          <FormGroup>
        <Form.Label for="filmtype">Type</Form.Label>
        <Form.Select type="select" name="filmtype" id="filmtype" value={item.filmtype || '0'} onChange={this.handleChange} >
              
          {Object.keys(this.state.filmtypes).map((key , i) => <option value={key}>{this.state.filmtypes[key]}</option>)}
        </Form.Select>
      </FormGroup>
      </Col>

      <Col md={6}>
          <FormGroup>
        <Form.Label for="filmtype">Cover</Form.Label>
        <Form.Select type="select" name="idcontainer" id="idcontainer" value={item.idcontainer || ''} onChange={this.handleChange} >
        <option value=""> -- No cover -- </option>            
            {this.state.covers.map((coverfilm) => <option value={coverfilm.idfilm}>{coverfilm.internationaltitle}</option>)}
        </Form.Select>
      </FormGroup>
      </Col>
      </Row>

      <Row form>
       
      
        <Col md={6}>
        <FormGroup>
            <Form.Label for="price">Price CHF (campo vuoto=non acquistabile, link-form vuoto + 0=gratuito): </Form.Label>
            <Form.Control type="text" name="price" id="price" value={item.price === 0 ? 0 : item.price || ''}
                   onChange={this.handleChange} />
          </FormGroup>  
          </Col>
        <Col md={6}>
          <FormGroup>
            <Form.Label for="bookfilmform">Link al form per prenotazione film: </Form.Label>
            <Form.Control type="text" name="bookfilmform" id="bookfilmform" value={item.bookfilmform || ''}
                          onChange={this.handleChange} />
          </FormGroup>
        </Col>
          
      </Row>

        <Tab.Container defaultActiveKey="ita">
          <Row className="mt-4">
            <Col xs={12}>
              <Nav variant="pills" className="flex-row mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="ita" className="px-5">ITA</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="eng" className="px-5">ENG</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fra" className="px-5">FRA</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12}>
              <Tab.Content>
                <Tab.Pane eventKey="ita">
                  <Row md={2}>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="shortsynopsis_it">Short Synopsis - <b>IT</b></Form.Label>
                        <ReactQuill theme="snow" name="shortsynopsis_it" id="shortsynopsis_it" value={item.shortsynopsis_it || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"shortsynopsis_it")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="synopsis_it">Synopsis - <b>IT</b></Form.Label>
                        <ReactQuill theme="snow"  name="synopsis_it" id="synopsis_it" value={item.synopsis_it || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"synopsis_it")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="directorstatement_it">Director statement - <b>IT</b></Form.Label>
                        <ReactQuill theme="snow"  name="directorstatement_it" id="directorstatement_it" value={item.directorstatement_it || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"directorstatement_it")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="trivia">Descrizione del progetto - <b>IT</b></Form.Label>
                        <ReactQuill theme="snow"  name="trivia_it" id="trivia_it" value={item.trivia_it || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"trivia_it")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="recommendedage">Recommended Age - <b>IT</b></Form.Label>
                        <Form.Control  type="text" name="recommendedage_it" id="recommendedage_it" value={item.recommendedage_it || ''}
                        onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="eng">
                  <Row md={2}>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="shortsynopsis_en">Short Synopsis - <b>EN</b></Form.Label>
                        <ReactQuill theme="snow" name="shortsynopsis_en" id="shortsynopsis_en" value={item.shortsynopsis_en || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"shortsynopsis_en")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="synopsis_en">Synopsis - <b>EN</b></Form.Label>
                        <ReactQuill theme="snow"  name="synopsis_en" id="synopsis_en" value={item.synopsis_en || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"synopsis_en")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="directorstatement_en">Director statement - <b>EN</b></Form.Label>
                        <ReactQuill theme="snow"  name="directorstatement_en" id="directorstatement_en" value={item.directorstatement_en || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"directorstatement_en")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="trivia_en">Project description - <b>EN</b></Form.Label>
                        <ReactQuill theme="snow"  name="trivia_en" id="trivia_en" value={item.trivia_en || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"trivia_en")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="recommendedage_en">Recommended Age - <b>EN</b></Form.Label>
                        <Form.Control  type="text" name="recommendedage_en" id="recommendedage_en" value={item.recommendedage_en|| ''}
                                       onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="fra">
                  <Row md={2}>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="shortsynopsis_fr">Short Synopsis - <b>FR</b></Form.Label>
                        <ReactQuill theme="snow" name="shortsynopsis_fr" id="shortsynopsis_fr" value={item.shortsynopsis_fr || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"shortsynopsis_fr")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="synopsis_fr">Synopsis - <b>FR</b></Form.Label>
                        <ReactQuill theme="snow"  name="synopsis_fr" id="synopsis_fr" value={item.synopsis_fr || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"synopsis_fr")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="directorstatement_fr">Director statement - <b>FR</b></Form.Label>
                        <ReactQuill theme="snow"  name="directorstatement_fr" id="directorstatement_fr" value={item.directorstatement_fr || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"directorstatement_fr")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="trivia_fr">Description du projet - <b>FR</b></Form.Label>
                        <ReactQuill theme="snow"  name="trivia_fr" id="trivia_fr" value={item.trivia_fr || ''}
                                    onChange={(html) => this.handleChangeEditor(html,"trivia_fr")} />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label for="recommendedage_fr">Recommended Age - <b>FR</b></Form.Label>
                        <Form.Control  type="text" name="recommendedage_fr" id="recommendedage_fr" value={item.recommendedage_fr || ''}
                                       onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        <Row form>
          <Col md={8}>
            <FormGroup>
              <Form.Label for="trailerlink">Trailer link: </Form.Label>
              <Form.Control  type="text" name="trailerlink" id="trailerlink" value={item.trailerlink || ''}
                     onChange={this.handleChange} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup className='mt-4'>
              <Button variant="primary" type="submit" className='me-2 w-25'>Save</Button>{' '}
              <Button variant="secondary" tag={Link} href="/filmtable">Cancel</Button>
            </FormGroup>
          </Col>
        </Row>

        </Form>
      </Container>
    </div>
  }
}

export default Addfilm;